<template>
  <modal
    contentClass="p-4 overflow-auto"
    wrapClass="modal-sm"
    :title="'Как улучшить показатели рекламы'"
    @hide="$emit('hide')"
    v-bind="{ ...$props, ...$attrs }"
  >
    <p class="gray-text fs-12">Навигация</p>
    <b-button
      @click="document.getElementById('arrival-fail').scrollIntoView({ behavior: 'smooth' })"
      class="text-left mt-2"
      size="md"
      variant="link"
      >Низкий приход подписчиков</b-button
    ><b-button
      @click="document.getElementById('price-fail').scrollIntoView({ behavior: 'smooth' })"
      class="text-left mt-2"
      size="md"
      variant="link"
      >Цена за подписчика вышла намного больше, чем ваша средняя</b-button
    >
    <h4 id="arrival-fail" class="mt-3">Низкий приход с рекламы</h4>
    <h5 class="mt-2">
      Если вы видите, что приход будет низким, а цена за подписчика гораздо больше, чем вы
      планировали, то можно написать следующее:
    </h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        {{ badArrival }}
      </p>
      <b-button class="mt-3" v-clipboard:copy="badArrival" variant="outline-default"
        >Скопировать текст сообщения</b-button
      >
    </div>
    <h5>
      Если вы понимаете, что дополнительная история сильно не увеличит приход, то используйте это
      сообщение:
    </h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        {{ badArrival }}
      </p>
      <b-button class="mt-3" v-clipboard:copy="badArrival" variant="outline-default"
        >Скопировать текст сообщения</b-button
      >
    </div>
    <h5>
      Если блогер не хочет возвращать всю сумму целиком, то предложите использовать следующую
      формулу:
    </h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        Сумма рекламы - (кол-во пришедших подписчиков * ваша средняя цена за подписчика) = та сумма,
        которую можно просить к возврату
      </p>
    </div>
    <h5>Если блогер не идет на контакт, то напишите:</h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        {{ noContact }}
      </p>
      <b-button v-clipboard:copy="noContact" class="mt-3" variant="outline-default"
        >Скопировать текст сообщения</b-button
      >
    </div>
    <h5>Затем ожидайте результатов рекламы. Если они хуже, чем средние, то пишите отзыв.</h5>
    <h4 id="price-fail" class="my-4" style="border-top: #d5dfe4">
      Цена за подписчика вышла намного больше, чем ваша средняя
    </h4>
    <h5>Попросите выложить дополнительные сторис в другой день:</h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        {{ badPrice }}
      </p>
      <b-button v-clipboard:copy="badPrice" class="mt-3" variant="outline-default"
        >Скопировать текст сообщения</b-button
      >
    </div>
    <h5>
      Если блогер не хочет возвращать всю сумму целиком, то предложите использовать следующую
      формулу:
    </h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        Сумма рекламы - (кол-во пришедших подписчиков * ваша средняя цена за подписчика) = та сумма,
        которую можно просить к возврату
      </p>
    </div>
    <h5>Если блогер не идет на контакт, то напишите:</h5>
    <div class="p-3 mt-3 mb-4" style="background-color: #fffce3; border-radius: 10px">
      <p>
        {{ noContact }}
      </p>
      <b-button v-clipboard:copy="noContact" class="mt-3" variant="outline-default"
        >Скопировать текст сообщения</b-button
      >
    </div>
    <h5>Затем ожидайте результатов рекламы. Если они хуже, чем средние, то пишите отзыв.</h5>
  </modal>
</template>

<script>
import Modal from '../../reusable/modals/Modal.vue';

export default {
  computed: {
    badArrival() {
      return `Очень плохо идут подписчики с вашей рекламы. На данный момент пришло
        ${this.model.arrival || '...'} человек. По нашим подсчетам общий приход составит около ${
        Math.ceil(Math.sqrt(this.result.armin * this.result.armax)) || '...'
      }. Хотим вас
        попросить выложить дополнительное сторис, чтобы увеличить приход. Будем вам очень благодарны`;
    },
    noContact() {
      return `Если приход будет гораздо меньше, чем у нас обычно выходит, то мы отразим это в отзыве на сайте easyprbot.com и в Отзовике`;
    },
    badPrice() {
      return `Здравствуйте\n\n
        Итоговая цена за подписчика составила ... рублей, что в разы выше, чем наша обычная цена в
        ... рубля. Возможно ли сделать дополнительное размещение 1-2 истории с отметкой нашего
        аккаунта в любой день на ваш выбор, чтобы снизить итоговую стоимость за подписчика, которую
        мы отразим в отзыве?`;
    },
  },
  components: { Modal },
  props: ['model', 'result'],
};
</script>
